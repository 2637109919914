import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Modal, Typography, Alert, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormTextField from "src/components/HookFormTextField";
import { useUpsertGiftToCart } from "src/hooks/order";
import { useSnackbar } from "notistack";
import LoginRequiredDialog from "src/features/login/LoginRequiredDialog";
import { useNavigate } from "react-router-dom";
import { useResendGiftInvitation } from "src/hooks/giftInvitation";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  name: yup.string().required("Recipient name is required"),
  senderName: yup.string().required("Sender name is required"),
  message: yup.string().required("Message is required"),
});

const BaseGiftModal = ({ open, onClose, title, initialValues, onSubmit }) => {
  const formMethods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (!open) {
      reset(initialValues);
    }
  }, [open, reset, initialValues]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="gift-modal-title"
      aria-describedby="gift-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40rem",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: "2rem",
          borderRadius: "0.5rem",
        }}
      >
        <Typography
          id="gift-modal-title"
          variant="h5"
          component="h2"
          gutterBottom
        >
          {title}
        </Typography>
        <Alert severity="info" sx={{ my: "1rem" }}>
          Please enter an email and message for this gift. Once it is purchased,
          the recipient will receive an email with a link to redeem their gift.
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <HookFormTextField
              name="name"
              label="Recipient Name"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="email"
              label="Email"
              fullWidth
              formMethods={formMethods}
            />
            <HookFormTextField
              name="message"
              label="Message"
              fullWidth
              formMethods={formMethods}
              minRows={3}
              maxRows={6}
              multiline
            />
            <HookFormTextField
              name="senderName"
              label="Sender Name"
              fullWidth
              formMethods={formMethods}
            />
          </Stack>
          <Stack
            justifyContent="end"
            direction="row"
            spacing={2}
            sx={{ mt: "1.5rem" }}
          >
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export const ShopGiftModal = ({ open, onClose, giftBundle }) => {
  const addGiftToCart = useUpsertGiftToCart();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.user || {});
  const senderName = `${userInfo.firstName || ""} ${
    userInfo.lastName || ""
  }`.trim();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    email: "",
    message: giftBundle.giftMessage || "",
    name: "",
    senderName: senderName || "",
  };

  const handleSubmit = async (data) => {
    try {
      await addGiftToCart.mutateAsync({
        productId: giftBundle.id,
        email: data.email,
        name: data.name,
        senderName: data.senderName,
        customMessage: data.message,
      });
      enqueueSnackbar("Added to Cart", { variant: "success" });
      navigate("/account/cart");
      onClose();
    } catch (error) {
      enqueueSnackbar("Failed to add to cart", { variant: "error" });
    }
  };

  return (
    <LoginRequiredDialog open={open} onClose={onClose}>
      <BaseGiftModal
        open={open}
        onClose={onClose}
        title="Confirm Add to Cart"
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </LoginRequiredDialog>
  );
};

export const CartGiftModal = ({ open, onClose, line }) => {
  const updateGiftToCart = useUpsertGiftToCart();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    name: line.giftInvitation.name || "",
    senderName: line.giftInvitation.senderName || "",
    email: line.giftInvitation.email || "",
    message: line.giftInvitation.message || "",
  };

  const handleSubmit = async (data) => {
    try {
      await updateGiftToCart.mutateAsync({
        inviteId: line.giftInvitation.id,
        productId: line.product.id,
        email: data.email,
        name: data.name,
        senderName: data.senderName,
        customMessage: data.message,
      });
      enqueueSnackbar("Updated Cart", { variant: "success" });
      onClose();
    } catch (error) {
      enqueueSnackbar("Failed to update cart", { variant: "error" });
    }
  };

  return (
    <LoginRequiredDialog open={open} onClose={onClose}>
      <BaseGiftModal
        open={open}
        onClose={onClose}
        title="Edit Gift Details"
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </LoginRequiredDialog>
  );
};

export const ResendGiftModal = ({ open, onClose, line }) => {
  const resendGiftInvitation = useResendGiftInvitation();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    name: line.giftInvitation.name || "",
    senderName: line.giftInvitation.senderName || "",
    email: line.giftInvitation.email || "",
    message: line.giftInvitation.message || "",
  };

  const handleSubmit = async (data) => {
    try {
      await resendGiftInvitation.mutateAsync({
        id: line.giftInvitation.id,
        email: data.email,
        name: data.name,
        senderName: data.senderName,
        customMessage: data.message,
      });
      enqueueSnackbar("Resent gift successfully", { variant: "success" });
      onClose();
    } catch (error) {
      enqueueSnackbar("Failed to resend gift", { variant: "error" });
    }
  };

  return (
    <LoginRequiredDialog open={open} onClose={onClose}>
      <BaseGiftModal
        open={open}
        onClose={onClose}
        title="Edit Gift Details"
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </LoginRequiredDialog>
  );
};
