import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import { SupportsWebp } from "src/slices/appSlice";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";
import QuillView from "src/components/QuillView";
import { ShopGiftModal } from "./GiftModal";

export default function GiftCard({ giftBundle }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const supportsWebp = useSelector(SupportsWebp);
  const navigate = useNavigate();
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(
        descriptionRef.current.scrollHeight >
          descriptionRef.current.clientHeight
      );
    }
  }, [giftBundle.descriptionAsQuill]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "40rem",
        width: "22rem",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-0.3rem)",
          boxShadow: 3,
        },
      }}
    >
      <CardMedia
        component="img"
        sx={{ height: "18rem" }}
        image={resolvePrimaryImageSrc(
          "product",
          giftBundle.id,
          giftBundle.primaryImageFileNamePrefix,
          "md",
          supportsWebp ? "webp" : "jpg"
        )}
        alt={giftBundle.name}
      />
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          pb: "5rem",
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {giftBundle.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: "1rem", fontStyle: "italic" }}
        >
          {giftBundle.shortDescription}
        </Typography>
        <Box
          ref={descriptionRef}
          sx={{
            position: "relative",
            maxHeight: "8rem",
            overflow: "hidden",
            "& .MuiTypography-root": {
              fontSize: "0.875rem",
            },
          }}
        >
          <QuillView value={giftBundle.descriptionAsQuill} />
          {isOverflowing && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "3rem",
                background: "linear-gradient(transparent, white)",
              }}
            />
          )}
        </Box>
        {isOverflowing && (
          <Button
            onClick={() => {
              navigate(`/gift/${giftBundle.id}`);
            }}
            sx={{
              alignSelf: "flex-start",
              color: "text.secondary",
              p: 0,
              mt: "0.5rem",
            }}
          >
            ...see more
          </Button>
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: "1rem",
            left: "1rem",
            right: "1rem",
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            sx={{ mb: "0.5rem", fontFamily: "karla" }}
          >
            ${giftBundle.priceUnitAmount.toFixed(2)}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            startIcon={<Iconify icon="mdi:cart" />}
            onClick={handleOpenModal}
          >
            Add to Cart
          </Button>
        </Box>
      </CardContent>

      <ShopGiftModal
        open={isModalOpen}
        onClose={handleCloseModal}
        giftBundle={giftBundle}
      />
    </Card>
  );
}
