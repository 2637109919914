import { useQuery, useMutation } from "react-query";
import api from "../services/api";

const getGiftInvitation = async ({ verifyToken, email }) => {
  // TODO: Probably won't care to verify the associated email? Unless we worry about it changing race?
  const { data } = await api.get(`/gift_invitation/`, {
    params: { verifyToken, email }, // or use ObjectToQueryString method
  });
  return data;
};

const resendGiftInvitation = async ({
  id,
  email,
  name,
  senderName,
  customMessage,
}) => {
  await api.post(`/gift_invitation/${id}/resend`, {
    email,
    name,
    senderName,
    customMessage,
  });
};

export const useLookupGiftInvitation = (verifyToken, email) => {
  return useQuery(
    ["lookupGiftInvitation", { verifyToken, email }],
    () => getGiftInvitation({ verifyToken, email }),
    { retry: false }
  );
};

export const useResendGiftInvitation = () => {
  // const queryClient = useQueryClient();
  return useMutation(resendGiftInvitation, {
    onSuccess: () => {
      //TODO: What should be invalidated? Maybe orders
    },
  });
};
